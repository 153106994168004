export enum PERMISSION_NAMES {
    OWNER = 'owner',
    MANAGER = 'manager',
    TRAINER = 'trainer',
}

export const PERMISSION_LABEL: { [index: string]: string } = {
    [PERMISSION_NAMES.OWNER]: 'Właściciel',
    [PERMISSION_NAMES.MANAGER]: 'Menadżer',
    [PERMISSION_NAMES.TRAINER]: 'Instruktor',
};

export const ACTIONS = {
    // users
    GET_CURRENT_USER: 'getCurrentUser',
    GET_USERS: 'getUsers',
    MANAGE_USERS: 'manageUsers',

    // stable
    GET_STABLE: 'getStableInfo',
    UPDATE_STABLE: 'updateStable',
    MENAGE_SUBSCRIPTION: 'menageSubscription',

    // horses
    CREATE_HORSE: 'createHorse',
    GET_HORSES: 'getHorses',
    GET_HORSE: 'getHorse',
    UPDATE_HORSE: 'updateHorse',
    DELETE_HORSE: 'deleteHorse',

    // ridingSpots
    GET_RIDING_SPOTS: 'getRidingSpot',
    CREATE_RIDING_SPOT: 'createRidingSpot',
    UPDATE_RIDING_SPOT: 'updateRidingSpot',
    DELETE_RIDING_SPOT: 'deleteRidingSpot',

    // clients
    GET_CLIENT: 'getClient',
    CREATE_CLIENT: 'createClient',
    UPDATE_CLIENT: 'updateClient',
    DELETE_CLIENT: 'deleteClient',

    // schedules
    GET_SCHEDULES: 'getSchedules',
    CREATE_SCHEDULE: 'createSchedule',
    UPDATE_SCHEDULE: 'updateSchedule',
    DELETE_SCHEDULE: 'deleteSchedule',

    // riding
    GET_RIDING: 'getRiding',
    CREATE_RIDING: 'createRiding',
    UPDATE_RIDING: 'updateRiding',
    DELETE_RIDING: 'deleteRiding',
};
