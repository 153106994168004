import Layout from '../components/Layout';
import Loadable from '../components/Loadable';
import { lazy } from 'react';
import RequireAuth from '../components/RequireAuth';

const LazyMail = Loadable(lazy(() => import('../pages/Mail')));

const Mail = {
    path: '/mail',
    element: (
        <RequireAuth>
            <Layout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/mail/:type/:id?',
            element: <LazyMail />,
        },
    ],
};

export default Mail;
