import { lazy } from 'react';
import Layout from '../components/Layout';
import Loadable from '../components/Loadable';
import RequireAuth from '../components/RequireAuth';

const LazyPaymentsList = Loadable(lazy(() => import('../pages/Payments/List')));

const Payments = {
    path: '/payments',
    element: (
        <RequireAuth>
            <Layout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/payments',
            element: <LazyPaymentsList />,
        },
    ],
};

export default Payments;
