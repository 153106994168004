import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ScheduleSlice {
    hasEvents: boolean;
}

const initialState: ScheduleSlice = {
    hasEvents: false,
};

export const scheduleSlice = createSlice({
    name: 'schedules',
    initialState,
    reducers: {
        setHasEventsState: (state, action: PayloadAction<boolean>) => {
            state.hasEvents = action.payload;
        },
    },
});

export const { setHasEventsState } = scheduleSlice.actions;

export default scheduleSlice.reducer;
