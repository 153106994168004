import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, CardProps, Divider, SxProps, Typography } from '@mui/material';

// ==============================|| CUSTOM SUB CARD ||============================== //

interface Props {
    contentClass?: string;
    secondary?: boolean;
    contentSX?: SxProps;
    contentBox?: boolean;
}

const SubCard = ({
    children,
    contentClass,
    secondary,
    contentBox = true,
    sx = {},
    contentSX = {},
    title,
    ...others
}: CardProps & Props) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                border: '1px solid',
                borderColor:
                    theme.palette.mode === 'dark' ? theme.palette.dark.light || 0 + 15 : theme.palette.grey[200],
                ':hover': {
                    boxShadow:
                        theme.palette.mode === 'dark'
                            ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                            : '0 2px 14px 0 rgb(32 40 45 / 8%)',
                },
                ...sx,
            }}
            {...others}
        >
            {title && (
                <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h5">{title}</Typography>} action={secondary} />
            )}

            {/* content & header divider */}
            {title && (
                <Divider
                    sx={{
                        opacity: 1,
                        borderColor:
                            theme.palette.mode === 'dark'
                                ? theme.palette.dark.light || 0 + 15
                                : theme.palette.grey[200],
                    }}
                />
            )}

            {/* card content */}
            {contentBox && (
                <CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ''}>
                    {children}
                </CardContent>
            )}
            {!contentBox && children}
        </Card>
    );
};

SubCard.defaultProps = {
    content: true,
};

export default SubCard;
