import { isProduction } from '../misc/helpers';
import { MenuOrientation, ThemeDirection, ThemeMode } from './theme.ts';
import { ConfigProps } from '../types/config.ts';

const Config: ConfigProps = {
    showThemeSwitcher: !isProduction(),
    menuOrientation: MenuOrientation.VERTICAL,
    miniDrawer: false,
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    mode: ThemeMode.LIGHT,
    presetColor: 'default',
    i18n: 'pl',
    themeDirection: ThemeDirection.LTR,
    container: false,
};

export default Config;
