import { Avatar } from '@mui/material';
import MD5 from 'crypto-js/md5';

interface Props {
    email?: string;
    name?: string;
    size?: 'small' | 'medium' | 'big';
}

const GAvatar = ({ email, name, size }: Props) => {
    const getSize = () => {
        switch (size) {
            case 'small':
                return '34px';
            case 'medium':
                return '60px';
            case 'big':
                return '120px';
            default:
                return '34px';
        }
    };

    const stringToColor = (string: string) => {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    };

    const stringAvatar = () => {
        if (!name) {
            return '';
        }

        const avaUrl = getAvatarImage();

        if (avaUrl) {
            return {
                src: avaUrl,
            };
        }

        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name[0][0]}${name[0][1]}`,
        };
    };

    const getAvatarImage = () => {
        if (!email) {
            return undefined;
        }

        const md5Email = MD5(email);

        return `https://www.gravatar.com/avatar/${md5Email}?d=retro`;
    };

    return (
        <Avatar
            alt={name || email || ''}
            {...stringAvatar()}
            sx={{
                width: getSize(),
                height: getSize(),
                fontSize: '1.2rem',
                margin: '0 auto',
            }}
        />
    );
};

export default GAvatar;
