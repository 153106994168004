import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

interface DashboardSlice {
    selectedDate: {
        from: Dayjs;
        to: Dayjs;
        label: string;
    };
}

const initialState: DashboardSlice = {
    selectedDate: {
        from: dayjs().startOf('day'),
        to: dayjs().endOf('day'),
        label: 'Dzisiaj',
    },
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setSelectedDate: (state, action: PayloadAction<{ from: Dayjs; to: Dayjs }>) => {
            state.selectedDate.from = action.payload.from;
            state.selectedDate.to = action.payload.to;
        },
        setSelectedDateLabel: (state, action: PayloadAction<string>) => {
            state.selectedDate.label = action.payload;
        },
    },
});

export const { setSelectedDate, setSelectedDateLabel } = dashboardSlice.actions;

export default dashboardSlice.reducer;
