import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './slices/user.slice';
import ConfigReducer from './slices/config.slice';
import StableReducer from './slices/stable.slice';
import RidingSpotsReducer from './slices/ridingSpots.slice';
import ClientsReducer from './slices/clients.slice';
import HorsesReducer from './slices/horses.slice.ts';
import MailReducer from './slices/mail.slice.ts';
import DashboardReducer from './slices/dashboard.slice';
import SubscriptionSlice from './slices/subscription.slice';
import SchedulesReducer from './slices/schedules.slice';

export const store = configureStore({
    reducer: {
        dashboard: DashboardReducer,
        stable: StableReducer,
        user: UserReducer,
        config: ConfigReducer,
        ridingSpots: RidingSpotsReducer,
        clients: ClientsReducer,
        horses: HorsesReducer,
        mail: MailReducer,
        subscription: SubscriptionSlice,
        schedules: SchedulesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
