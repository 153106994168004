import axios from 'axios';
import { AuthEntity } from '../entities/AuthEntity';

type LoginData = {
    email: string;
    password: string;
};

export const loginService = (loginData: LoginData) => {
    return axios.post<AuthEntity>('/auth/login', loginData);
};

export const logOutService = (refreshToken: string) => {
    return axios.post('/auth/logout', { refreshToken });
};

export const verifyEmailService = (token: string) => {
    return axios.post(`/auth/verify-email?token=${token}`);
};

export const forgotPasswordService = (email: string) => {
    return axios.post(`/auth/forgot-password`, { email });
};

export const sendInvitationEmailService = (email: string) => {
    return axios.post(`/auth/send-invitation-email`, { email });
};

export const resetPasswordService = (token: string, password: string) => {
    return axios.post(`/auth/reset-password?token=${token}`, { password });
};

export const testEmailService = () => {
    return axios.post(`/auth/test-email`);
};
