import Layout from '../components/Layout';
import Loadable from '../components/Loadable';
import { lazy } from 'react';
import RequireAuth from '../components/RequireAuth';

const LazyUsers = Loadable(lazy(() => import('../pages/Users/List')));
const LazyEditUser = Loadable(lazy(() => import('../pages/Users/Edit')));
const LazyUserProfile = Loadable(lazy(() => import('../pages/Users/Profile')));

const Users = {
    path: '/users',
    element: (
        <RequireAuth>
            <Layout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/users',
            element: <LazyUsers />,
        },
        {
            path: '/users/edit',
            element: <LazyEditUser />,
        },
        {
            path: '/users/edit/:id',
            element: <LazyEditUser />,
        },
        {
            path: '/users/profile/:id',
            element: <LazyUserProfile />,
        },
    ],
};

export default Users;
