interface Props {
    width?: number;
    height?: number;
    onlyLogo?: boolean;
}
const LogoVertical = ({ width = 100, height, onlyLogo }: Props) => {
    if (onlyLogo) {
        return (
            <svg
                width={width}
                height={height}
                id="Warstwa_2"
                data-name="Warstwa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 314.22 470.67"
            >
                <g id="Warstwa_6" data-name="Warstwa 6">
                    <g>
                        <g>
                            <path
                                d="M144.03,419.02c1.81-9.75,6.69-17.84,14.54-23.84,11-8.4,23.57-11.02,37.26-9.01-13.27-8.14-31.34-12.21-49.2-5.35-17.28,6.64-27.5,17.91-28.43,36.81-1.08,22.04,15.08,40.83,38.36,45.87,5.36,1.16,10.95,1.28,16.44,1.87.04-.46.07-.92.11-1.38-21.89-5.72-32.75-25.15-29.08-44.97Z"
                                style={{ fill: '#00a0d4', strokeWidth: '0px' }}
                            />
                            <path
                                d="M207.54,402.55c-9.89-4.9-20.35-4.56-29.73,1.94-8.55,5.93-13.24,16.93-11.94,26.34,13.16-13.2,25.68-14.45,37.64-3.77,12.36,11.04,11.86,26.1-2.26,43.61,3.1-1,5.32-1.35,7.15-2.37,13.22-7.41,20.6-18.68,20.3-33.94-.29-14.8-8.29-25.44-21.16-31.81Z"
                                style={{ fill: '#00a0d4', strokeWidth: '0px' }}
                            />
                        </g>
                        <g>
                            <path
                                d="M247.16,332.94c18.12-11.49,30.47-27.81,38.53-47.07,8.56-20.45,10.58-41.98,6.24-64.04-4.69-23.84-16.01-43.97-33.22-61,.62,1.61,1.39,3.19,1.85,4.84,3.99,14.27,9.57,28.33,11.52,42.88,3.52,26.31-3.18,50.59-18.77,72.55-10.36,14.59-23.88,25.09-39.89,31.59-13.1,5.32-27.34,7.89-41.15,11.38-22.24,5.62-42.24,14.55-55.43,34.7-6.68,10.2-10.24,21.29-11.76,34.07,1.11-.98,1.63-1.25,1.87-1.68,9.51-17.05,23.95-28.15,42.56-32.97,13.21-3.42,26.86-5.29,40.39-7.26,20.09-2.92,39.7-6.85,57.27-17.99Z"
                                style={{ fill: '#0364aa', strokeWidth: '0px' }}
                            />
                            <path
                                d="M299.39,279.28c-4.25,9.89-8.01,21.06-13.6,31.21-15.61,28.36-40.2,44.78-71.86,49.97-14.91,2.44-30.15,2.9-43.96,4.14,1,.29,3.33,1.02,5.7,1.63,18.31,4.74,36.29,11.06,55.62,10.72,20.94-.37,38.87-7.39,52.1-23.94,17.81-22.26,20.83-47.83,16-73.73Z"
                                style={{ fill: '#0364aa', strokeWidth: '0px' }}
                            />
                        </g>
                        <g>
                            <path
                                d="M254.2,183.13c-2.7,7.95-5.05,16.66-8.56,24.86-9.79,22.85-25.03,40.31-49.65,47.89-16.12,4.97-32.65,4.54-49.21,3.63-21.56-1.18-42.42,1.01-61.97,11.25-13.75,7.2-24.65,17.27-32.58,30.82.56-.3,1.22-.49,1.67-.9,9.3-8.73,20.41-14.62,32.52-17.65,17.28-4.33,34.81-2.72,52.19.74,17.16,3.42,34.06,8.11,51.96,7.24,22.55-1.1,40.04-10.91,53.39-28.55,10.17-13.44,13.22-29.35,14.17-45.73.68-11.71-.64-23.24-3.92-33.6Z"
                                style={{ fill: '#002b5f', strokeWidth: '0px' }}
                            />
                            <path
                                d="M100.51,310.24c-34.9,12.21-45.64,45.97-33.35,78.12,4.94,12.94,12.75,23.92,22.46,33.56,6.22,6.18,13.01,11.79,19.54,17.66-28.7-46.97-16.14-114.89,53.91-126.15-1.72-1.12-2.47-1.87-3.38-2.16-19.62-6.22-39.35-7.98-59.17-1.04Z"
                                style={{ fill: '#002b5f', strokeWidth: '0px' }}
                            />
                        </g>
                        <g>
                            <path
                                d="M310.18,127.23c-8.81-4.06-17.81-7.76-26.4-12.24-24.85-12.97-45.51-31.92-68.05-48.2-7.26-5.24-15.14-9.62-22.73-14.4-1.3-.82-2.58-1.65-3.87-2.48v-1.53c2.03-.23,4.13-.94,6.06-.62,27.86,4.62,52.73,15.65,73.1,35.56,12.53,12.24,24.48,25.07,36.76,37.56,1.77,1.8,3.94,3.2,5.93,4.78-.27.52-.53,1.04-.8,1.56Z"
                                style={{ fill: '#262626', strokeWidth: '0px' }}
                            />
                            <path
                                d="M314.11,228.97c-4.29-15.9-9.99-31.39-18.51-45.53-7.44-12.35-16.29-23.83-24.16-35.93-9.54-14.67-18.69-29.59-27.64-44.62,42.84,31.29,72.49,69.89,70.31,126.08Z"
                                style={{ fill: '#262626', strokeWidth: '0px' }}
                            />
                            <path
                                d="M232.59,207.64c-9.13,14.1-20.43,24.11-35.03,30.67-15.22,6.85-31.26,7.21-47.48,7.58-15.29.35-30.67-.19-45.69,3.96-26.91,7.42-48.1,22.42-61.56,47.11-9.16,16.81-13.85,34.9-11.88,54.32.07.72-.56,1.5-1.15,2.99-7.92-20.14-9.88-40.17-8.58-60.79.88-13.85,3.68-27.04,9.31-39.74,9.17-20.66,24.23-35.67,43.5-46.97,17.05-10.01,35.83-13.61,55.06-15.85,3.99-.47,7.93-1.37,11.9-2.07.33.42.66.84.99,1.26-5.4,5-10.27,10.77-16.3,14.83-11.95,8.05-24.68,14.92-36.78,22.74-5.43,3.51-10.12,8.14-15.15,12.27.35.49.71.99,1.06,1.48,4.08-1.4,8.27-2.57,12.23-4.24,22.54-9.55,46.37-10.66,70.3-10.54,18.73.09,37.12-2.12,54.85-7.96,6.91-2.28,13.02-6.95,20.4-11.03Z"
                                style={{ fill: '#262626', strokeWidth: '0px' }}
                            />
                            <path
                                d="M225.04,89.41c-13.91-18.03-32.13-30.05-52.89-38.65-3.93-1.63-7.86-2.94-7.42-8.73.16-2.13-1.42-4.84-2.98-6.58-10.58-11.76-21.37-23.33-32.55-35.46-3.65,15.7-4.5,29.96,5.51,42.49-5.11,1.65-10.11,2.33-14.08,4.71-10.63,6.38-21.16,13.05-31.13,20.41-25.22,18.63-50.17,37.64-75.08,56.68-3.82,2.92-8.08,6.26-10.07,10.4-6.52,13.53-5.74,27.15,2.72,39.81,12.11,18.12,37.64,20.02,49.08,3.39,4.05-5.89,9.51-8.36,16.2-9.56,11.83-2.13,22.47,2.12,32.95,6.24,11.57,4.55,23.29,8.05,35.74,7.31,20.82-1.23,39.95-15.16,43.55-36.5,2.31-13.7-.01-26.76-6.08-39.21.11,9.99-1.82,19.29-5.8,28.44-9.89,22.76-33.29,30.47-54.46,24.14-11.78-3.53-23.14-9.13-35.88-8.62-15.32.61-29.55,2.63-37.42,19.03-4.63,9.65-12.58,10.49-22.02,5.96-3.64-1.75-6.46-5.9-8.83-9.5-5.38-8.14-3.74-27.05,6.87-32.47,16.08-8.21,31.59-17.54,47.53-26.03,17.26-9.19,34.64-18.16,52.15-26.86,9.26-4.6,19.07-8.11,28.29-12.77,3.78-1.91,7.09-2.11,10.59-.68,6.36,2.59,12.81,5.1,18.8,8.4,29.88,16.49,47.18,41.8,50.24,75.83,1.94,21.63-3.48,41.8-17.97,59.82,1.44-.56,1.96-.64,2.32-.93,2.48-1.95,4.97-3.88,7.36-5.94,15.41-13.27,24.69-29.92,26.27-50.27,1.89-24.34-6.85-45.36-21.49-64.34Z"
                                style={{ fill: '#262626', strokeWidth: '0px' }}
                            />
                            <path
                                d="M116.37,95.48c-.57,4.75,5.51,10.86,10.43,10.55,3.85-.24,6.63-2.34,6.87-6.51.31-5.17-4.55-9.9-10.04-10.04-4.22-.17-6.78,2.03-7.26,6Z"
                                style={{ fill: '#262626', strokeWidth: '0px' }}
                            />
                            <path
                                d="M25.31,144.74c-1.99.61-3.53,2.67-5.26,4.12.2.68.4,1.35.6,2.03,2.49.31,5.04,1,7.46.71.76-.09,2.08-3.31,1.57-4.25-.73-1.35-3.21-2.97-4.37-2.61Z"
                                style={{ fill: '#262626', strokeWidth: '0px' }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

    return (
        <svg
            width={width}
            height={height}
            id="Warstwa_2"
            data-name="Warstwa 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 456.85 618.18"
        >
            <g id="Warstwa_6" data-name="Warstwa 6">
                <g>
                    <g>
                        <path
                            d="M213.44,419.02c1.81-9.75,6.69-17.84,14.54-23.84,11-8.4,23.57-11.02,37.26-9.01-13.27-8.14-31.34-12.21-49.2-5.35-17.28,6.64-27.5,17.91-28.43,36.81-1.08,22.04,15.08,40.83,38.36,45.87,5.36,1.16,10.95,1.28,16.44,1.87.04-.46.07-.92.11-1.38-21.89-5.72-32.75-25.15-29.08-44.97Z"
                            style={{ fill: '#00a0d4' }}
                        />
                        <path
                            d="M276.95,402.55c-9.89-4.9-20.35-4.56-29.73,1.94-8.55,5.93-13.24,16.93-11.94,26.34,13.16-13.2,25.68-14.45,37.64-3.77,12.36,11.04,11.86,26.1-2.26,43.61,3.1-1,5.32-1.35,7.15-2.37,13.22-7.41,20.6-18.68,20.3-33.94-.29-14.8-8.29-25.44-21.16-31.81Z"
                            style={{ fill: '#00a0d4' }}
                        />
                    </g>
                    <g>
                        <path
                            d="M316.57,332.94c18.12-11.49,30.47-27.81,38.53-47.07,8.56-20.45,10.58-41.98,6.24-64.04-4.69-23.84-16.01-43.97-33.22-61,.62,1.61,1.39,3.19,1.85,4.84,3.99,14.27,9.57,28.33,11.52,42.88,3.52,26.31-3.18,50.59-18.77,72.55-10.36,14.59-23.88,25.09-39.89,31.59-13.1,5.32-27.34,7.89-41.15,11.38-22.24,5.62-42.24,14.55-55.43,34.7-6.68,10.2-10.24,21.29-11.76,34.07,1.11-.98,1.63-1.25,1.87-1.68,9.51-17.05,23.95-28.15,42.56-32.97,13.21-3.42,26.86-5.29,40.39-7.26,20.09-2.92,39.7-6.85,57.27-17.99Z"
                            style={{ fill: '#0364aa' }}
                        />
                        <path
                            d="M368.8,279.28c-4.25,9.89-8.01,21.06-13.6,31.21-15.61,28.36-40.2,44.78-71.86,49.97-14.91,2.44-30.15,2.9-43.96,4.14,1,.29,3.33,1.02,5.7,1.63,18.31,4.74,36.29,11.06,55.62,10.72,20.94-.37,38.87-7.39,52.1-23.94,17.81-22.26,20.83-47.83,16-73.73Z"
                            style={{ fill: '#0364aa' }}
                        />
                    </g>
                    <g>
                        <path
                            d="M323.61,183.13c-2.7,7.95-5.05,16.66-8.56,24.86-9.79,22.85-25.03,40.31-49.65,47.89-16.12,4.97-32.65,4.54-49.21,3.63-21.56-1.18-42.42,1.01-61.97,11.25-13.75,7.2-24.65,17.27-32.58,30.82.56-.3,1.22-.49,1.67-.9,9.3-8.73,20.41-14.62,32.52-17.65,17.28-4.33,34.81-2.72,52.19.74,17.16,3.42,34.06,8.11,51.96,7.24,22.55-1.1,40.04-10.91,53.39-28.55,10.17-13.44,13.22-29.35,14.17-45.73.68-11.71-.64-23.24-3.92-33.6Z"
                            style={{ fill: '#002b5f' }}
                        />
                        <path
                            d="M169.92,310.24c-34.9,12.21-45.64,45.97-33.35,78.12,4.94,12.94,12.75,23.92,22.46,33.56,6.22,6.18,13.01,11.79,19.54,17.66-28.7-46.97-16.14-114.89,53.91-126.15-1.72-1.12-2.47-1.87-3.38-2.16-19.62-6.22-39.35-7.98-59.17-1.04Z"
                            style={{ fill: '#002b5f' }}
                        />
                    </g>
                    <g>
                        <path
                            d="M379.59,127.23c-8.81-4.06-17.81-7.76-26.4-12.24-24.85-12.97-45.51-31.92-68.05-48.2-7.26-5.24-15.14-9.62-22.73-14.4-1.3-.82-2.58-1.65-3.87-2.48v-1.53c2.03-.23,4.13-.94,6.06-.62,27.86,4.62,52.73,15.65,73.1,35.56,12.53,12.24,24.48,25.07,36.76,37.56,1.77,1.8,3.94,3.2,5.93,4.78-.27.52-.53,1.04-.8,1.56Z"
                            style={{ fill: '#262626' }}
                        />
                        <path
                            d="M383.52,228.97c-4.29-15.9-9.99-31.39-18.51-45.53-7.44-12.35-16.29-23.83-24.16-35.93-9.54-14.67-18.69-29.59-27.64-44.62,42.84,31.29,72.49,69.89,70.31,126.08Z"
                            style={{ fill: '#262626' }}
                        />
                        <path
                            d="M302,207.64c-9.13,14.1-20.43,24.11-35.03,30.67-15.22,6.85-31.26,7.21-47.48,7.58-15.29.35-30.67-.19-45.69,3.96-26.91,7.42-48.1,22.42-61.56,47.11-9.16,16.81-13.85,34.9-11.88,54.32.07.72-.56,1.5-1.15,2.99-7.92-20.14-9.88-40.17-8.58-60.79.88-13.85,3.68-27.04,9.31-39.74,9.17-20.66,24.23-35.67,43.5-46.97,17.05-10.01,35.83-13.61,55.06-15.85,3.99-.47,7.93-1.37,11.9-2.07.33.42.66.84.99,1.26-5.4,5-10.27,10.77-16.3,14.83-11.95,8.05-24.68,14.92-36.78,22.74-5.43,3.51-10.12,8.14-15.15,12.27.35.49.71.99,1.06,1.48,4.08-1.4,8.27-2.57,12.23-4.24,22.54-9.55,46.37-10.66,70.3-10.54,18.73.09,37.12-2.12,54.85-7.96,6.91-2.28,13.02-6.95,20.4-11.03Z"
                            style={{ fill: '#262626' }}
                        />
                        <path
                            d="M294.45,89.41c-13.91-18.03-32.13-30.05-52.89-38.65-3.93-1.63-7.86-2.94-7.42-8.73.16-2.13-1.42-4.84-2.98-6.58-10.58-11.76-21.37-23.33-32.55-35.46-3.65,15.7-4.5,29.96,5.51,42.49-5.11,1.65-10.11,2.33-14.08,4.71-10.63,6.38-21.16,13.05-31.13,20.41-25.22,18.63-50.17,37.64-75.08,56.68-3.82,2.92-8.08,6.26-10.07,10.4-6.52,13.53-5.74,27.15,2.72,39.81,12.11,18.12,37.64,20.02,49.08,3.39,4.05-5.89,9.51-8.36,16.2-9.56,11.83-2.13,22.47,2.12,32.95,6.24,11.57,4.55,23.29,8.05,35.74,7.31,20.82-1.23,39.95-15.16,43.55-36.5,2.31-13.7-.01-26.76-6.08-39.21.11,9.99-1.82,19.29-5.8,28.44-9.89,22.76-33.29,30.47-54.46,24.14-11.78-3.53-23.14-9.13-35.88-8.62-15.32.61-29.55,2.63-37.42,19.03-4.63,9.65-12.58,10.49-22.02,5.96-3.64-1.75-6.46-5.9-8.83-9.5-5.38-8.14-3.74-27.05,6.87-32.47,16.08-8.21,31.59-17.54,47.53-26.03,17.26-9.19,34.64-18.16,52.15-26.86,9.26-4.6,19.07-8.11,28.29-12.77,3.78-1.91,7.09-2.11,10.59-.68,6.36,2.59,12.81,5.1,18.8,8.4,29.88,16.49,47.18,41.8,50.24,75.83,1.94,21.63-3.48,41.8-17.97,59.82,1.44-.56,1.96-.64,2.32-.93,2.48-1.95,4.97-3.88,7.36-5.94,15.41-13.27,24.69-29.92,26.27-50.27,1.89-24.34-6.85-45.36-21.49-64.34Z"
                            style={{ fill: '#262626' }}
                        />
                        <path
                            d="M185.78,95.48c-.57,4.75,5.51,10.86,10.43,10.55,3.85-.24,6.63-2.34,6.87-6.51.31-5.17-4.55-9.9-10.04-10.04-4.22-.17-6.78,2.03-7.26,6Z"
                            style={{ fill: '#262626' }}
                        />
                        <path
                            d="M94.72,144.74c-1.99.61-3.53,2.67-5.26,4.12.2.68.4,1.35.6,2.03,2.49.31,5.04,1,7.46.71.76-.09,2.08-3.31,1.57-4.25-.73-1.35-3.21-2.97-4.37-2.61Z"
                            style={{ fill: '#262626' }}
                        />
                    </g>
                </g>
                <g>
                    <path
                        d="M0,562.65v-60.66h10.83l9.33,23.8c1.83,4.67,4.79,12.43,6.49,17.68h.18c-.42-6.34-1.2-16.93-1.2-28.07v-13.41h10.36v60.66h-10.83l-9.2-23.07c-2.06-5.12-4.79-12.78-6.2-18.22h-.18c.35,6.09.77,15.45.77,27.65v13.64H0Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M56.03,548.79l-2.66,13.85h-11.44l13.17-60.66h14.12l11.79,60.66h-11.48l-2.59-13.85h-10.91ZM65.9,539.64l-2.02-12.7c-.58-3.69-1.39-9.78-1.99-13.79h-.22c-.65,4.03-1.55,10.3-2.18,13.81l-2.22,12.68h8.64Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M86.29,550.56c2.44,1.4,6.62,2.38,9.98,2.38,5.56,0,8.34-2.89,8.34-6.88,0-4.33-2.75-6.55-7.89-9.84-8.29-5.09-11.43-11.44-11.43-16.95,0-9.74,6.46-17.81,19.17-17.81,4.03,0,7.78,1.12,9.53,2.18l-1.88,10.14c-1.72-1.08-4.31-2.07-7.71-2.07-5.06,0-7.48,3.06-7.48,6.27,0,3.64,1.83,5.56,8.35,9.48,8.05,4.83,10.97,10.97,10.97,17.34,0,11.14-8.19,18.4-20.02,18.4-4.9,0-9.6-1.23-11.63-2.43l1.71-10.2Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M118.87,555.75l19.22-43.22v-.25h-17.44v-10.29h30.72v8.71l-18.37,41.39v.27h18.28v10.29h-32.42v-6.9Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M168.44,548.79l-2.66,13.85h-11.45l13.17-60.66h14.12l11.79,60.66h-11.48l-2.59-13.85h-10.91ZM178.31,539.64l-2.02-12.7c-.58-3.69-1.39-9.78-1.99-13.79h-.22c-.65,4.03-1.55,10.3-2.18,13.81l-2.22,12.68h8.64Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M213.01,550.56c2.44,1.4,6.62,2.38,9.98,2.38,5.56,0,8.34-2.89,8.34-6.88,0-4.33-2.75-6.55-7.89-9.84-8.29-5.09-11.43-11.44-11.43-16.95,0-9.74,6.46-17.81,19.17-17.81,4.03,0,7.78,1.12,9.53,2.18l-1.88,10.14c-1.72-1.08-4.31-2.07-7.71-2.07-5.06,0-7.48,3.06-7.48,6.27,0,3.64,1.83,5.56,8.35,9.48,8.05,4.83,10.97,10.97,10.97,17.34,0,11.14-8.19,18.4-20.02,18.4-4.9,0-9.6-1.23-11.63-2.43l1.7-10.2Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M256.36,512.91h-10.7v-10.92h33.29v10.92h-10.77v49.73h-11.82v-49.73Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M291.74,548.79l-2.66,13.85h-11.44l13.17-60.66h14.12l11.79,60.66h-11.48l-2.59-13.85h-10.91ZM301.61,539.64l-2.02-12.7c-.58-3.69-1.39-9.78-1.99-13.79h-.22c-.65,4.03-1.55,10.3-2.18,13.81l-2.22,12.68h8.64Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M332.02,501.99h11.82v41.31c0,17.48-8.45,20.07-17.03,20.07-2.41,0-4.65-.37-5.94-.88l1-9.93c1.09.32,2.32.48,3.82.48,3.44,0,6.33-1.42,6.33-9.22v-41.84Z"
                        style={{ fill: '#262626' }}
                    />
                    <path
                        d="M353.51,562.65v-60.66h10.83l9.33,23.8c1.83,4.67,4.79,12.43,6.49,17.68h.18c-.42-6.34-1.2-16.93-1.2-28.07v-13.41h10.36v60.66h-10.83l-9.2-23.07c-2.06-5.12-4.79-12.78-6.2-18.22h-.18c.35,6.09.77,15.45.77,27.65v13.64h-10.36Z"
                        style={{ fill: '#262626' }}
                    />
                    <path d="M411.77,501.99v60.66h-11.82v-60.66h11.82Z" style={{ fill: '#262626' }} />
                    <path
                        d="M431.86,548.79l-2.66,13.85h-11.44l13.17-60.66h14.12l11.79,60.66h-11.48l-2.59-13.85h-10.91ZM441.73,539.64l-2.02-12.7c-.58-3.69-1.39-9.78-1.99-13.79h-.22c-.65,4.03-1.55,10.3-2.18,13.81l-2.22,12.68h8.64Z"
                        style={{ fill: '#262626' }}
                    />
                </g>
                <g>
                    <path
                        d="M16.08,604.34l-2.23,6.78h-2.89l7.33-21.57h3.35l7.37,21.57h-2.98l-2.31-6.78h-7.64ZM23.16,602.16l-2.13-6.21c-.47-1.41-.79-2.7-1.11-3.93h-.08c-.31,1.26-.65,2.58-1.07,3.9l-2.12,6.24h6.52Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M38.37,608.25c.85.53,2.33,1.1,3.73,1.1,2.05,0,3.01-1.01,3.01-2.3s-.82-2.06-2.88-2.83c-2.8-1.01-4.12-2.53-4.11-4.39,0-2.49,2.03-4.54,5.35-4.54,1.57,0,2.93.43,3.79.95l-.7,2.05c-.6-.37-1.73-.91-3.17-.91-1.66,0-2.56.96-2.56,2.12,0,1.28.91,1.85,2.94,2.64,2.67.99,4.05,2.34,4.06,4.65,0,2.74-2.13,4.66-5.79,4.66-1.69,0-3.26-.44-4.35-1.08l.68-2.12Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M59.33,595.64l3.38,9.15c.36,1.02.74,2.24,1,3.16h.08c.28-.91.62-2.1.99-3.22l3.06-9.08h2.99l-4.23,11.02c-2,5.32-3.38,8.03-5.3,9.71-1.39,1.2-2.76,1.68-3.45,1.81l-.7-2.35c.71-.22,1.62-.66,2.47-1.37.76-.62,1.7-1.71,2.35-3.13.14-.3.22-.5.22-.68,0-.16-.05-.38-.2-.72l-5.71-14.27h3.06Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M79.68,608.25c.85.53,2.33,1.1,3.73,1.1,2.05,0,3.01-1.01,3.01-2.3s-.82-2.06-2.88-2.83c-2.8-1.01-4.12-2.53-4.11-4.39,0-2.49,2.03-4.54,5.35-4.54,1.58,0,2.93.43,3.79.95l-.7,2.05c-.59-.37-1.73-.91-3.17-.91-1.66,0-2.56.96-2.56,2.12,0,1.28.91,1.85,2.94,2.64,2.67.99,4.05,2.34,4.06,4.65,0,2.74-2.13,4.66-5.79,4.66-1.69,0-3.26-.44-4.35-1.08l.68-2.12Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M103.01,591.19v4.46h4.04v2.13h-4.04v8.34c0,1.93.55,3.02,2.13,3.02.73,0,1.26-.09,1.63-.2l.13,2.14c-.55.22-1.42.39-2.51.39-1.29,0-2.37-.43-3.04-1.19-.77-.85-1.09-2.22-1.09-4.04v-8.45h-2.4v-2.13h2.4v-3.73l2.76-.73Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M118.48,603.89c.06,3.8,2.47,5.37,5.3,5.37,2.01,0,3.25-.36,4.29-.79l.49,2c-.98.45-2.7.99-5.16.99-4.75,0-7.61-3.17-7.61-7.81s2.76-8.35,7.28-8.35c5.05,0,6.37,4.43,6.37,7.28,0,.59-.03,1.01-.1,1.32h-10.85ZM126.72,601.86c.03-1.77-.73-4.56-3.88-4.56-2.87,0-4.1,2.6-4.32,4.56h8.2Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M139.82,599.82c0-1.62-.05-2.9-.13-4.18h2.49l.16,2.54h.08c.76-1.45,2.56-2.9,5.12-2.9,2.13,0,5.45,1.28,5.45,6.59v9.23h-2.8v-8.92c0-2.5-.94-4.6-3.58-4.6-1.85,0-3.29,1.31-3.79,2.89-.12.34-.19.84-.19,1.31v9.33h-2.8v-11.29Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M167.71,591.19v4.46h4.04v2.13h-4.04v8.34c0,1.93.56,3.02,2.13,3.02.73,0,1.26-.09,1.63-.2l.13,2.14c-.55.22-1.42.39-2.51.39-1.29,0-2.37-.43-3.04-1.19-.78-.85-1.09-2.22-1.09-4.04v-8.45h-2.4v-2.13h2.4v-3.73l2.76-.73Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M198.35,591.9h-6.57v-2.35h15.97v2.35h-6.6v19.21h-2.8v-19.21Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M216.76,595.64l2.08,7.85c.45,1.73.84,3.35,1.15,4.94h.08c.34-1.56.86-3.24,1.39-4.92l2.53-7.88h2.37l2.41,7.74c.55,1.83,1.01,3.48,1.37,5.05h.08c.28-1.57.7-3.2,1.2-5.03l2.21-7.76h2.77l-5,15.47h-2.56l-2.35-7.38c-.56-1.74-1-3.28-1.37-5.1h-.06c-.39,1.87-.87,3.47-1.42,5.13l-2.49,7.36h-2.57l-4.67-15.47h2.86Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M259.78,603.23c0,5.73-3.98,8.24-7.7,8.24-4.2,0-7.44-3.06-7.44-7.97,0-5.18,3.4-8.21,7.68-8.21s7.45,3.23,7.45,7.94ZM247.51,603.41c0,3.39,1.95,5.95,4.69,5.95s4.71-2.52,4.71-6.03c0-2.62-1.32-5.93-4.66-5.93s-4.74,3.08-4.74,6.01Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M266.43,615.59c1.3-.09,2.4-.45,3.07-1.19.77-.87,1.08-2.1,1.08-5.81v-12.95h2.8v14.03c0,3.01-.48,4.96-1.85,6.38-1.24,1.25-3.29,1.74-4.8,1.75l-.3-2.21ZM273.72,591.27c0,.94-.66,1.75-1.79,1.75-1.04,0-1.71-.81-1.7-1.75,0-.97.72-1.75,1.77-1.75s1.71.79,1.72,1.75Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M286.45,603.89c.06,3.8,2.47,5.37,5.3,5.37,2.01,0,3.25-.36,4.29-.79l.49,2c-.98.45-2.7.99-5.16.99-4.75,0-7.61-3.17-7.61-7.81s2.76-8.35,7.28-8.35c5.05,0,6.37,4.43,6.37,7.28,0,.59-.03,1.01-.1,1.32h-10.85ZM294.69,601.86c.03-1.77-.73-4.56-3.88-4.56-2.87,0-4.1,2.6-4.32,4.56h8.2Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M304,615.59c1.3-.09,2.4-.45,3.07-1.19.77-.87,1.08-2.1,1.08-5.81v-12.95h2.8v14.03c0,3.01-.48,4.96-1.85,6.38-1.24,1.25-3.29,1.74-4.8,1.75l-.3-2.21ZM311.29,591.27c0,.94-.66,1.75-1.79,1.75-1.04,0-1.71-.81-1.7-1.75,0-.97.72-1.75,1.77-1.75s1.71.79,1.72,1.75Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M335.83,607.72c1.25.78,3.06,1.4,4.98,1.4,2.85,0,4.52-1.49,4.52-3.67,0-2-1.17-3.18-4.08-4.27-3.52-1.25-5.7-3.1-5.7-6.12,0-3.36,2.79-5.85,6.99-5.85,2.19,0,3.82.51,4.76,1.04l-.78,2.28c-.69-.41-2.13-1.02-4.07-1.02-2.96,0-4.08,1.75-4.08,3.22,0,2,1.31,2.99,4.29,4.14,3.66,1.43,5.5,3.19,5.5,6.35s-2.44,6.22-7.55,6.22c-2.09,0-4.36-.63-5.51-1.41l.73-2.32Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M362.05,591.19v4.46h4.04v2.13h-4.04v8.34c0,1.93.55,3.02,2.13,3.02.73,0,1.26-.09,1.63-.2l.13,2.14c-.55.22-1.42.39-2.51.39-1.29,0-2.37-.43-3.04-1.19-.78-.85-1.09-2.22-1.09-4.04v-8.45h-2.4v-2.13h2.4v-3.73l2.76-.73Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M384.72,611.12l-.25-1.96h-.08c-.85,1.21-2.52,2.31-4.73,2.31-3.13,0-4.73-2.22-4.73-4.44,0-3.75,3.32-5.81,9.3-5.76v-.33c0-1.26-.35-3.59-3.52-3.57-1.44,0-2.95.43-4.03,1.15l-.64-1.87c1.28-.83,3.15-1.36,5.11-1.36,4.73,0,5.88,3.23,5.88,6.33v5.79c0,1.32.06,2.64.23,3.7h-2.54ZM384.28,603.22c-3.04-.06-6.53.48-6.53,3.48,0,1.85,1.21,2.69,2.63,2.69,2.04,0,3.32-1.28,3.77-2.59.1-.3.13-.62.13-.89v-2.69Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M394.65,615.59c1.3-.09,2.4-.45,3.07-1.19.77-.87,1.08-2.1,1.08-5.81v-12.95h2.8v14.03c0,3.01-.48,4.96-1.85,6.38-1.24,1.25-3.29,1.74-4.8,1.75l-.3-2.21ZM401.94,591.27c0,.94-.66,1.75-1.79,1.75-1.04,0-1.71-.81-1.7-1.75,0-.97.72-1.75,1.77-1.75s1.71.79,1.72,1.75Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M413.1,599.82c0-1.62-.05-2.9-.13-4.18h2.49l.16,2.54h.08c.76-1.45,2.56-2.9,5.12-2.9,2.13,0,5.45,1.28,5.45,6.59v9.23h-2.8v-8.92c0-2.5-.94-4.6-3.58-4.6-1.85,0-3.29,1.31-3.79,2.89-.12.34-.19.84-.19,1.31v9.33h-2.8v-11.29Z"
                        style={{ fill: '#0364aa' }}
                    />
                    <path
                        d="M440.88,591.27c0,.96-.67,1.75-1.79,1.75-1.02,0-1.7-.79-1.7-1.75,0-.97.72-1.75,1.75-1.75s1.73.77,1.74,1.75ZM437.74,611.12v-15.47h2.8v15.47h-2.8Z"
                        style={{ fill: '#0364aa' }}
                    />
                </g>
            </g>
        </svg>
    );
};

export default LogoVertical;
