import { useTheme } from '@mui/material/styles';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
    Badge,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grow,
    Paper,
    Popper,
    Stack,
    Tab,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { IconBell, IconMessage2 } from '@tabler/icons-react';
import MainCard from '../../cards/MainCard.tsx';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import MessageList from './MessageList.tsx';
import { AvatarSecondaryStyles } from '../../../styles/avatar.ts';
import { TabsSmallStyles } from '../../../styles/tabs.ts';
import { useErrorHandler } from '../../../controllers/Error';
import { getMessagesService, setMessageAsReadService } from '../../../services/message.service.ts';
import { MessageEntity } from '../../../entities/MessageEntity.ts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store.ts';
import { setMessages, updateUnread } from '../../../slices/mail.slice.ts';
import ChangelogList from './ChangelogList.tsx';
import { extractLatestVersion } from '../../../misc/helpers.ts';

interface TabProps {
    children: ReactNode;
    index: number;
    value: number;
}

const TabPanel = ({ children, value, index, ...other }: TabProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const Notifications = () => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [stableMessages, setStableMessages] = useState<Array<MessageEntity>>([]);
    const [appMessages, setAppMessages] = useState<Array<MessageEntity>>([]);
    const [newVersion, setNewVersion] = useState(false);

    const { messages, unread } = useSelector((state: RootState) => state.mail);

    const theme = useTheme();
    const dispatch = useDispatch();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef(null);
    let abortController: AbortController | null = null;
    let interval: NodeJS.Timeout | null = null;

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleReadAllClick = () => {
        const unreadMessages = stableMessages.filter((item: MessageEntity) => !item.isRead);
        const promises: Array<Promise<unknown>> = [];

        if (!unreadMessages.length) {
            return;
        }

        unreadMessages.forEach((item: MessageEntity) => {
            promises.push(setMessageAsReadService(item.id));
        });

        Promise.all(promises)
            .then(() => {
                getMessages();
            })
            .catch((error) => {
                errorHandler({ error });
            });
    };

    const renderTab = (label: string, count: number) => {
        const counter = count ? (
            <Grid item>
                <Chip
                    label={count}
                    size="small"
                    sx={{ color: theme.palette.secondary.main, background: theme.palette.secondary.light, ml: 1.3 }}
                />
            </Grid>
        ) : null;

        return (
            <Grid container alignItems={'center'}>
                <Grid item>{label}</Grid>
                {counter}
            </Grid>
        );
    };

    const getMessages = useCallback(() => {
        abortController = new AbortController();

        getMessagesService({ signal: abortController.signal })
            .then(({ data }) => {
                dispatch(setMessages(data.results));
                dispatch(updateUnread());
            })
            .catch((error) => {
                errorHandler({ error });
            });
    }, []);

    useEffect(() => {
        const version = extractLatestVersion();

        if (localStorage.getItem('NSV') !== version) {
            setNewVersion(true);
            localStorage.setItem('NSV', version);
        }
    }, []);

    useEffect(() => {
        getMessages();

        interval = setInterval(
            () => {
                getMessages();
            },
            1000 * 60 * 5,
        );

        return () => {
            if (interval) {
                clearInterval(interval);
            }

            abortController?.abort();
        };
    }, []);

    useEffect(() => {
        const stable = messages.filter((item: MessageEntity) => item.type === 'stable' && !item.isRead);
        const app = messages.filter((item: MessageEntity) => item.type === 'app' && !item.isRead);

        setStableMessages(stable);
        setAppMessages(app);
    }, [messages]);

    return (
        <>
            <Box
                sx={{
                    mx: 2,
                    [theme.breakpoints.down('lg')]: {
                        mr: 2,
                    },
                }}
            >
                <Badge color="error" badgeContent={unread.all || (newVersion ? 'NEW' : null)}>
                    <AvatarSecondaryStyles
                        variant="circular"
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="20px" />
                    </AvatarSecondaryStyles>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20],
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Grow in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard elevation={16} content={false}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    sx={{ pt: 2, px: 2 }}
                                                >
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <IconMessage2 color={theme.palette.secondary.main} />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            component={Button}
                                                            onClick={handleReadAllClick}
                                                            variant="subtitle2"
                                                            color="primary"
                                                        >
                                                            Oznacz wszystkie jako przeczytane
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TabsSmallStyles
                                                            value={value}
                                                            indicatorColor="primary"
                                                            onChange={handleChange}
                                                            aria-label="simple tabs example"
                                                            variant="scrollable"
                                                        >
                                                            <Tab
                                                                component={Link}
                                                                to="#"
                                                                label={renderTab('Stajnia', unread.stable)}
                                                                {...a11yProps(0)}
                                                            />
                                                            <Tab
                                                                component={Link}
                                                                to="#"
                                                                label={renderTab('Aplikacja', unread.app)}
                                                                {...a11yProps(1)}
                                                            />
                                                            <Tab
                                                                component={Link}
                                                                to="#"
                                                                label={renderTab('Nowości', newVersion ? 1 : 0)}
                                                                {...a11yProps(2)}
                                                            />
                                                        </TabsSmallStyles>
                                                        <TabPanel value={value} index={0}>
                                                            <MessageList
                                                                messages={stableMessages}
                                                                updateMessages={getMessages}
                                                            />
                                                        </TabPanel>
                                                        <TabPanel value={value} index={1}>
                                                            <MessageList
                                                                messages={appMessages}
                                                                updateMessages={getMessages}
                                                            />
                                                        </TabPanel>
                                                        <TabPanel value={value} index={2}>
                                                            <ChangelogList />
                                                        </TabPanel>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button size="small" disableElevation onClick={() => navigate('/mail/all')}>
                                                Zobacz wszystkie
                                            </Button>
                                        </CardActions>
                                    </MainCard>
                                )}
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default Notifications;
