import axios from 'axios';
import { EventTypeEntity } from '../entities/EventTypeEntity';

export const getEventTypesService = () => {
    return axios.get<Array<EventTypeEntity>>('/event-types');
};

export const updateEventTypePricesService = (data: { prices: Array<{ price: number; id: string }> }) => {
    return axios.patch<Array<EventTypeEntity>>('/event-types/prices', data);
};
