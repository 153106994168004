import axios from 'axios';
import { Cookie } from '../misc/helpers.ts';

const token = Cookie.get('kkt');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
axios.defaults.baseURL =
    process.env.NODE_ENV === 'production' ? 'https://api.nasza-stajnia.pl/v1' : 'http://localhost:3000/v1';
axios.defaults.withCredentials = false;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
