import { useEffect, useRef, useState } from 'react';
import {
    Box,
    Card,
    Chip,
    ClickAwayListener,
    Divider,
    Fade,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material';
import { UserEntity } from '../../entities/UserEntity';
import GAvatar from '../GAvatar';
import { useTheme } from '@mui/material/styles';
import { IconEdit, IconLogout, IconSettings } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PERMISSION_LABEL } from '../../controllers/Permissions/consts.ts';
import { useAppDispatch } from '../../hooks.ts';
import { useErrorHandler } from '../../controllers/Error';
import { logOutService } from '../../services/auth.service.ts';
import { Cookie } from '../../misc/helpers.ts';
import { setStable } from '../../slices/stable.slice.ts';
import { logOutUser, setUser } from '../../slices/user.slice.ts';
import useTranslate from '../../hooks/useTranslate.ts';

interface Props {
    user: UserEntity | null;
}

const UserMenuAvatar = ({ user }: Props) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const theme = useTheme();
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const dispatch = useAppDispatch();
    const errorHandler = useErrorHandler();
    const { __ } = useTranslate();

    const logout = async () => {
        try {
            await logOutService(Cookie.get('kkrt'));

            dispatch(setStable(null));
            dispatch(setUser(null));
            dispatch(logOutUser());

            Cookie.delete('kkt');
            Cookie.delete('kkrt');
        } catch (error) {
            errorHandler({ error });
        }
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleListItemClick = (event: MouseEvent | TouchEvent, index: number, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            anchorRef?.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor:
                        theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light,
                        },
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0,
                    },
                    paddingLeft: 1,
                }}
                icon={<GAvatar email={user?.email} name={user?.firstName} />}
                label={<IconSettings stroke={1.5} size="24px" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14],
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <>
                        <ClickAwayListener onClickAway={handleClose}>
                            <Fade
                                in={open}
                                timeout={{
                                    appear: 0,
                                    enter: 400,
                                    exit: 200,
                                }}
                                {...TransitionProps}
                            >
                                <Paper>
                                    {open && (
                                        <Card elevation={14}>
                                            <Box sx={{ p: 2, pb: 0 }}>
                                                <Stack>
                                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                                        <Typography variant="h4">Dzień dobry,</Typography>
                                                        <Typography
                                                            component="span"
                                                            variant="h4"
                                                            sx={{ fontWeight: 400 }}
                                                        >
                                                            {user?.firstName}
                                                        </Typography>
                                                    </Stack>
                                                    <Typography variant="subtitle2">
                                                        {user?.role.map((role) => (
                                                            <span style={{ paddingRight: '5px' }} key={role}>
                                                                {PERMISSION_LABEL[role]}
                                                            </span>
                                                        ))}
                                                    </Typography>
                                                </Stack>
                                                {/*<Divider />*/}
                                            </Box>
                                            <PerfectScrollbar
                                                style={{
                                                    height: '100%',
                                                    maxHeight: 'calc(100vh - 250px)',
                                                    overflowX: 'hidden',
                                                }}
                                            >
                                                <Box sx={{ p: 2, pt: 0 }}>
                                                    {/*UpgradePlanCard*/}
                                                    <Divider sx={{ pt: 2 }} />
                                                    <List
                                                        component="nav"
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 350,
                                                            minWidth: 300,
                                                            backgroundColor: theme.palette.background.paper,
                                                            borderRadius: '10px',
                                                            [theme.breakpoints.down('md')]: {
                                                                minWidth: '100%',
                                                            },
                                                            '& .MuiListItemButton-root': {
                                                                mt: 0.5,
                                                            },
                                                        }}
                                                    >
                                                        <ListItemButton
                                                            sx={{ borderRadius: `5px` }}
                                                            selected={selectedIndex === 0}
                                                            onClick={(event) =>
                                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                                //@ts-ignore
                                                                handleListItemClick(event, 0, `/users/edit/${user?.id}`)
                                                            }
                                                        >
                                                            <ListItemIcon>
                                                                <IconEdit stroke={1.5} size="20px" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="body2">
                                                                        {__('Edytuj dane')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ borderRadius: `5px` }}
                                                            selected={selectedIndex === 4}
                                                            onClick={logout}
                                                        >
                                                            <ListItemIcon>
                                                                <IconLogout stroke={1.5} size="20px" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="body2">
                                                                        Wyloguj mnie
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                    </List>
                                                </Box>
                                            </PerfectScrollbar>
                                        </Card>
                                    )}
                                </Paper>
                            </Fade>
                        </ClickAwayListener>
                    </>
                )}
            </Popper>
        </>
    );
};

export default UserMenuAvatar;
