import { styled } from '@mui/material/styles';
import { Box, List } from '@mui/material';

export const NotificationsListStyles = styled(List)(({ theme }) => ({
    // width: '100%',
    width: 400,
    minHeight: 250,
    padding: 0,
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
        maxWidth: 300,
    },
    '& .MuiListItemSecondaryAction-root': {
        top: 22,
    },
    '& .MuiDivider-root': {
        marginTop: 0,
        marginBottom: 0,
    },
    '& .list-container': {
        paddingLeft: '3.5rem',
    },
}));

export const NotificationsListBoxStyles = styled(Box)(() => ({
    height: '100%',
    maxHeight: 'calc(100vh - 305px)',
    overflowX: 'hidden',
}));

export const NotificationsListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    },
    '& .MuiListItem-root': {
        padding: 0,
    },
}));
