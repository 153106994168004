import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useCallback, useEffect } from 'react';
import { PaletteMode } from '@mui/material';
import { setColorMode } from './slices/config.slice';
import { getHorsesService } from './services/horses.service.ts';
import { useErrorHandler } from './controllers/Error';
import { setHorses } from './slices/horses.slice.ts';
import { HorseEntity } from './entities/HorseEntity.ts';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useChangeColorMode = () => {
    const dispatch = useDispatch();

    const mode = localStorage.getItem('colorMode') === 'dark' ? 'dark' : 'light';

    const setColor = useCallback(
        (mode: PaletteMode) => {
            dispatch(setColorMode(mode));
        },
        [dispatch],
    );

    const handleChangeColorMode = () => handleSaveColorMode(mode === 'light' ? 'dark' : 'light');

    const handleSaveColorMode = (mode: PaletteMode) => {
        localStorage.setItem('colorMode', mode);
        setColor(mode);
    };

    useEffect(() => {
        setColor(mode);
    }, []);

    return { handleChangeColorMode, mode };
};

export const useHorses = () => {
    const horses = useAppSelector((state) => state.horses.list);
    const errorHandler = useErrorHandler();
    const dispatch = useDispatch();
    const getHorsesAbortController = new AbortController();

    const getHorses = () =>
        new Promise<HorseEntity[]>((resolve, reject) => {
            if (horses) {
                return resolve(horses);
            }

            getHorsesService(
                { pageSize: 1000 },
                {
                    signal: getHorsesAbortController.signal,
                },
            )
                .then(({ data: { results } }) => {
                    dispatch(setHorses(results));
                    resolve(results);
                })
                .catch((error) => {
                    errorHandler({ error });
                    reject(error);
                });
        });

    return { getHorses, getHorsesAbortController };
};
