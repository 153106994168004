const CHANGELOG_VERSIONS_TO_DISPLAY = 3;

const removeATags = (html: string) => {
    return html.replaceAll(/<a.*?>(.*?)<\/a>/g, '$1');
};

const extractDate = (input: string): string | null => {
    const regex = /\((\d{4}-\d{2}-\d{2})\)/;
    const match = input.match(regex);

    return match ? match[1] : null;
};

type ChangesObjectType = {
    type: string;
    changes: Array<string>;
};

export type ChangesDataType = Array<ChangesObjectType>;

type ChangeLogObjectType = {
    version: string;
    date: string;
    changes: ChangesDataType;
};

export type ChangeLogDataType = Array<ChangeLogObjectType>;

export const getRecentAppChanges = (changeLogString: string): ChangeLogDataType => {
    const data: ChangeLogDataType = [];

    const versionChangesSeparator = '<h2';

    const changesPerVersion = changeLogString.split(versionChangesSeparator);

    changesPerVersion.reduce((changesPerVersion, currentValue, currentIndex) => {
        if (currentIndex <= CHANGELOG_VERSIONS_TO_DISPLAY) {
            const singleVersionLog = versionChangesSeparator + currentValue;
            const parsedVersionChanges = parseVersionChanges(singleVersionLog);

            if (parsedVersionChanges) {
                data.push(parsedVersionChanges);
            }
        }
        return changesPerVersion;
    }, '');

    return data;
};

const parseVersionChanges = (changesLog: string) => {
    const data: ChangeLogObjectType = {
        version: '',
        date: '',
        changes: [],
    };

    const parsedChangeLog = new DOMParser().parseFromString(removeATags(changesLog), 'text/html');
    const listOfChanges: ChangesDataType = [];

    Array.from(parsedChangeLog.getElementsByTagName('h3')).forEach((type) => {
        const data: ChangesObjectType = {
            type: type.innerText,
            changes: [],
        };

        if (type.nextElementSibling?.children) {
            Array.from(type.nextElementSibling?.children).forEach((change) => {
                data.changes.push(change.innerHTML.replace(/\([a-zA-Z0-9]{7}\)/, '').trim());
            });
        }

        listOfChanges.push(data);
    });

    data.changes = listOfChanges;

    const versionHeading = parsedChangeLog.querySelector('h2');

    if (versionHeading?.innerText) {
        data.date = extractDate(versionHeading.innerText) ?? '';
        data.version = versionHeading.innerText.replace(/\(\d{4}-\d{2}-\d{2}\)/, '').trim() ?? '';
    }

    if (!data.version) {
        return null;
    }

    return data;
};
