const ERROR_CODES: Record<number, string> = {
    100: 'Adres email lub hasło są nieprawidłowe.',
    103: 'Adres email jest już zajęty.',
    104: 'Stajnia o podanym adresie email widnieje już w naszej bazie danych.',

    400: 'Wyczerpano dostępną ilość kont pracowników w wybranym planie subskrypcji. W każdej chwili możesz zwiększyć swój limit w Ustawieniach > Plan i rozliczenie.',

    600: 'Aktualinie do Twojej stajni są przypisane aktywne subskrypcje. Aby usunąć stajnię, musisz najpierw anulować subskrypcję.',
};

export default ERROR_CODES;
