import { Box, Button, Divider, Fab, Grid, IconButton, Typography, useTheme } from '@mui/material';
import Confetti from 'react-canvas-confetti/dist/presets/realistic';
import SubCard from '../../cards/SubCard';
import { Link, useLocation } from 'react-router-dom';
import { IconCheckbox, IconDirections, IconMinus, IconSquare } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useErrorHandler } from '../../../controllers/Error';
import { updateStable } from '../../../services/stable.service';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setStable } from '../../../slices/stable.slice';
import { TOnInitPresetFn } from 'react-canvas-confetti/dist/types';
import { motion } from 'framer-motion';
import useTranslate from '../../../hooks/useTranslate';

type TasksType = {
    users: boolean;
    horses: boolean;
    ridingSpots: boolean;
    clients: boolean;
    schedule: boolean;
};

type TasksKeysType = keyof TasksType;

const IntroductionGame = () => {
    const state = useAppSelector((state) => state);

    const [tasks, setTasks] = useState<TasksType | null>(null);
    const [showWindow, setShowWindowState] = useState(true);
    const [displayConfettiAnimation, setDisplayConfettiState] = useState(false);

    const location = useLocation();
    const handleError = useErrorHandler();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { __ } = useTranslate();

    const toggleWindow = () => {
        setShowWindowState(!showWindow);
    };

    const handleClose = async () => {
        try {
            const { data } = await updateStable({ introductionCompleted: true });

            dispatch(setStable(data));
        } catch (error) {
            handleError({ error });
        }
    };

    const updateTasks = (taskName: TasksKeysType) => {
        if (!tasks) {
            return;
        }

        setTasks((prev) => {
            if (!prev) {
                return prev;
            }

            return {
                ...prev,
                [taskName]: true,
            };
        });

        setTimeout(() => {
            setDisplayConfettiState(true);
        }, 500);

        setTimeout(() => {
            setDisplayConfettiState(false);
        }, 3000);
    };

    const onInitConfetti: TOnInitPresetFn = (params) => {
        params.confetti({
            origin: { y: 0.7 },
        });
    };

    const renderCheckbox = (task: boolean) => {
        return (
            <Box sx={{ position: 'relative', height: 24 }}>
                <Box
                    component={motion.div}
                    initial={{ scale: 1 }}
                    animate={task ? { scale: 1.5, rotate: -20, opacity: 0 } : false}
                    transition={{ delay: 0.7 }}
                    sx={{ position: 'absolute', top: 0 }}
                >
                    <IconSquare />
                </Box>

                <Box
                    component={motion.div}
                    initial={{ scale: 1.5, rotate: -20, opacity: 0 }}
                    animate={task ? { scale: 1, rotate: 0, opacity: 1 } : false}
                    transition={{ delay: 1 }}
                    sx={{ position: 'absolute', top: 0 }}
                >
                    <IconCheckbox color={theme.palette.secondary.main} />
                </Box>
            </Box>
        );
    };

    const renderCongratulationMsg = () => {
        if (!tasks) {
            return null;
        }

        const allDone = Object.values(tasks).every((task) => task);

        if (allDone) {
            return (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom color={theme.palette.primary.main}>
                            {__('Gratulacje! 🎉')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} mb={1}>
                        <Typography variant="body1">
                            {__('Wprowadzenie zostało zakończone. Możesz teraz przejść do korzystania z aplikacji.')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} mb={1}>
                        <Typography variant="body1">
                            {__('Pamiętaj, że w razie jakichkolwiek pytań, jesteśmy do Twojej dyspozycji. 😊👍')}
                        </Typography>
                    </Grid>
                </>
            );
        }

        return null;
    };

    const renderTasks = () => {
        if (!tasks) {
            return null;
        }

        const allDone = Object.values(tasks).every((task) => task);

        if (allDone) {
            return null;
        }

        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom color={theme.palette.secondary.main}>
                        {__('Witaj!')} 🎉😊
                    </Typography>
                </Grid>

                <Grid item xs={12} mb={1}>
                    <Typography variant="body1">
                        {__(
                            'Poniżej znajdziesz kilka zadań, które pomogą Ci zapoznać się z aplikacją. W każdej chwili możesz zakończyć wprowadzenie.',
                        )}
                    </Typography>
                </Grid>

                <Grid item xs={12} mb={1}>
                    <Divider />
                </Grid>

                <Grid item xs={1}>
                    {renderCheckbox(tasks?.users)}
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="body1">
                        {__('Zobacz stronę, poprzez którą możesz zarządzać pracownikami stajni.')}{' '}
                        <Link to="/users">{__('Przejdź do strony')}</Link>
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    {renderCheckbox(tasks?.horses)}
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="body1">
                        {__(
                            'Dodaj swojego pierwszego konia. Przejdź do strony "Nasze Konie", a następnie kliknij "Dodaj Konia".',
                        )}{' '}
                        <Link to="/horses">{__('Przejdź do strony')}</Link>
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    {renderCheckbox(tasks?.ridingSpots)}
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="body1">
                        {__(
                            'Dodaj miejsca, w których odbywają się jazdy (przykładowo: Ujeżdżalnia, Hala itp.). Przejdź do strony "Miejsca Jazd", a następnie kliknij "Dodaj Miejsce Jazdy".',
                        )}{' '}
                        <Link to="/riding-spots">{__('Przejdź do strony')}</Link>
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    {renderCheckbox(tasks?.clients)}
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="body1">
                        {__(
                            'Dodaj pierwszego klienta! Przejdź do strony "Klienci", a następnie kliknij "Dodaj Klienta".',
                        )}{' '}
                        <Link to="/clients">{__('Przejdź do strony')}</Link>
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    {renderCheckbox(tasks?.schedule)}
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="body1">
                        {__(
                            'Dodaj pierwsze wydarzenie do terminarza. Przejdź do strony "Terminarz", a następnie kliknij plusik lub wybierz odpowiedni czas na kalendarzu.',
                        )}{' '}
                        <Link to="/schedule">{__('Przejdź do strony')}</Link>
                    </Typography>
                </Grid>
            </>
        );
    };

    useEffect(() => {
        if (location.pathname === '/users') {
            updateTasks('users');
        }
    }, [location]);

    useEffect(() => {
        if (tasks) {
            localStorage.setItem('tasks', JSON.stringify(tasks));
        }
    }, [tasks]);

    useEffect(() => {
        const tasks = localStorage.getItem('tasks');

        if (tasks) {
            setTasks(JSON.parse(tasks));
        } else {
            setTasks({
                users: false,
                horses: false,
                ridingSpots: false,
                clients: false,
                schedule: false,
            });
        }
    }, []);

    useEffect(() => {
        if (state?.horses?.list?.length) {
            updateTasks('horses');
        }

        if (state?.ridingSpots?.spots?.length) {
            updateTasks('ridingSpots');
        }

        if (state?.clients?.list?.length) {
            updateTasks('clients');
        }

        if (state?.schedules.hasEvents) {
            updateTasks('schedule');
        }
    }, [state?.horses, state?.ridingSpots, state?.clients, state?.schedules.hasEvents]);

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 180,
                    right: 20,
                    minWidth: 350,
                    maxWidth: 500,
                    display: showWindow ? 'block' : 'none',
                    zIndex: 10,
                    [theme.breakpoints.down('md')]: {
                        bottom: 80,
                    },
                }}
            >
                <SubCard
                    elevation={20}
                    sx={{
                        ['&:before']: {
                            bottom: '-23px',
                            content: '" "',
                            display: 'block',
                            height: '20px',
                            right: '18px',
                            position: 'absolute',
                            borderColor: '#fff transparent transparent transparent',
                            borderStyle: 'solid',
                            borderWidth: '12px',
                        },
                    }}
                    contentSX={{
                        border: '2px solid',
                        borderColor: theme.palette.secondary.main,
                        margin: '5px',
                        borderRadius: '5px',
                    }}
                >
                    <IconButton
                        aria-label="minimalize"
                        size="small"
                        sx={{ position: 'absolute', top: 15, right: 15, backgroundColor: 'white' }}
                        onClick={toggleWindow}
                    >
                        <IconMinus fontSize="inherit" />
                    </IconButton>

                    <Grid container spacing={2} alignItems={'center'}>
                        {renderCongratulationMsg()}

                        {renderTasks()}

                        <Grid item xs={12} mt={2}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="text" color="primary" fullWidth onClick={handleClose}>
                                {__('Zakończ wprowadzenie')}
                            </Button>
                        </Grid>
                    </Grid>
                </SubCard>
            </Box>

            <Fab
                color="secondary"
                sx={{
                    position: 'fixed',
                    bottom: 100,
                    right: 20,
                    [theme.breakpoints.down('md')]: {
                        bottom: 15,
                    },
                }}
                aria-label="introduction"
                onClick={toggleWindow}
            >
                <IconDirections />
            </Fab>

            {displayConfettiAnimation ? (
                <Confetti
                    onInit={onInitConfetti}
                    style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 1000, width: 500, height: '100vh' }}
                />
            ) : null}
        </>
    );
};

export default IntroductionGame;
