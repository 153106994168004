import axios, { AxiosRequestConfig } from 'axios';
import { CollectionEntity } from '../entities/ResponseEntity.ts';
import { MessageEntity } from '../entities/MessageEntity.ts';

export const getMessagesService = (options?: AxiosRequestConfig) => {
    return axios.get<CollectionEntity<MessageEntity>>(`/messages`, options);
};

export const setMessageAsReadService = (id: string) => {
    return axios.patch(`/messages/${id}`, { isRead: true });
};
