import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

export const TabsSmallStyles = styled(Tabs)(({ theme }) => ({
    minHeight: 'auto',
    '& button': {
        minWidth: 100,
    },
    '& a': {
        minHeight: 'auto',
        minWidth: 10,
        paddingY: 1.5,
        paddingX: 2,
        marginRight: 2.25,
        color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
        fontWeight: 400,
        textTransform: 'uppercase',
        fontSize: '0.75rem',
    },
    '& a.Mui-selected': {
        color: 'primary.main',
    },
}));
