import { Suspense } from 'react';
import Loader from '../Loader';

const Loadable = (Component: React.FC) => (props: Record<string, unknown>) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;
