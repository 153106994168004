import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConfigSlice {
    colorMode: 'light' | 'dark';
    drawerOpen: boolean;
}

const initialState: ConfigSlice = {
    colorMode: localStorage.getItem('colorMode') === 'dark' ? 'dark' : 'light',
    drawerOpen: true,
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setColorMode: (state, action: PayloadAction<'light' | 'dark'>) => {
            state.colorMode = action.payload;
        },
        setDrawerOpenState: (state, action: PayloadAction<boolean>) => {
            state.drawerOpen = action.payload;
        },
    },
});

export const { setColorMode, setDrawerOpenState } = configSlice.actions;

export default configSlice.reducer;
