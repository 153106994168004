import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

const chipSX = {
    height: 24,
    lineHeight: 1,
    padding: '0 6px',
};

export const ChipErrorStyles = styled(Chip)(({ theme }) => ({
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
    marginRight: '5px',
}));

export const ChipWarningStyles = styled(Chip)(({ theme }) => ({
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
}));

export const ChipSuccessStyles = styled(Chip)(({ theme }) => ({
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
}));
