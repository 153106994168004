import * as Sentry from '@sentry/react';
import { version } from '../../package.json';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { isProduction } from './helpers';

export const initErrorReporting = () => {
    if (!isProduction()) {
        return;
    }

    Sentry.init({
        dsn: 'https://a19407d279094c02026cb6afd02d161b@o4505940586332160.ingest.sentry.io/4505940601143296',
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/api.nasza-stajnia\.pl/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        release: version,
        environment: isProduction() ? 'production' : 'development',
    });
};

initErrorReporting();
