import Layout from '../components/Layout';
import Loadable from '../components/Loadable';
import { lazy } from 'react';
import RequireAuth from '../components/RequireAuth';

const LazySchedule = Loadable(lazy(() => import('../pages/Schedule')));

const Schedule = {
    path: '/schedule',
    element: (
        <RequireAuth>
            <Layout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/schedule',
            element: <LazySchedule />,
        },
    ],
};

export default Schedule;
