import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';

export const AvatarSecondaryStyles = styled(Avatar)(({ theme }) => ({
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
    '&[aria-controls="menu-list-grow"],&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
        color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light,
    },
}));
