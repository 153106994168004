import {
    Avatar,
    CircularProgress,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { NotificationsListItemWrapper } from '../../../styles/notifications.ts';
import { ChipErrorStyles } from '../../../styles/chip.ts';
import { MessageEntity } from '../../../entities/MessageEntity.ts';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import { useErrorHandler } from '../../../controllers/Error';
import { setMessageAsReadService } from '../../../services/message.service.ts';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
    message: MessageEntity;
    updateMessages: () => void;
}

const MessageListItem = ({ message, updateMessages }: Props) => {
    const [pending, setPending] = useState(false);
    const handleError = useErrorHandler();
    const navigate = useNavigate();

    const handleReadClick = async () => {
        setPending(true);

        try {
            await setMessageAsReadService(message.id);

            updateMessages();
        } catch (error) {
            handleError({ error });
        }

        setPending(false);
    };

    return (
        <>
            <NotificationsListItemWrapper onClick={() => navigate(`/mail/${message.type}/${message.id}`)}>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar alt="John Doe" />
                    </ListItemAvatar>
                    <ListItemText primary={message.title} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end" sx={{ mt: -2 }}>
                            <Grid item xs={12}>
                                {/*<Typography variant="caption" display="block" gutterBottom>*/}
                                {/*    2 min ago*/}
                                {/*</Typography>*/}

                                <Tooltip placement="top" title="Oznacz jako przeczytane">
                                    <IconButton
                                        onClick={handleReadClick}
                                        color="default"
                                        size="medium"
                                        aria-label="read"
                                        sx={{ mt: 1 }}
                                    >
                                        {pending ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <AssignmentTurnedInTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">{message.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            {message.isRead ? null : (
                                <Grid item>
                                    <ChipErrorStyles label="Nowa" />
                                </Grid>
                            )}

                            {/*<Grid item>*/}
                            {/*    <ChipWarningStyles label="New" />*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                </Grid>
            </NotificationsListItemWrapper>
            <Divider />
        </>
    );
};

export default MessageListItem;
