// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import { useEffect, useState } from 'react';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const [menuItems, setMenuItems] = useState<typeof import('../../../../../menu-items')>();

    const getMenuItems = async () => {
        const items = await import('../../../../../menu-items');

        setMenuItems(items);
    };

    const navGroups = () => {
        return menuItems?.default.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Fix - Navigation Group
                        </Typography>
                    );
            }
        });
    };

    useEffect(() => {
        getMenuItems();
    }, []);

    if (!menuItems?.default.items.length) {
        return null;
    }

    return <Box sx={{ pt: 2 }}>{navGroups()}</Box>;
};

export default Navigation;
