import { useIntl } from 'react-intl';

/**
 * @returns  {Function} __ - (text: string, data?: Record<string, any>) => string
 * 1. Tekst przesyłany do funkcji nie może zawierać kodu HTML
 * 2. Dane do tekstu przekazyje się poprzez 'text {example} text'
 */
const useTranslate = () => {
    const intl = useIntl();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const __ = (text: string, data?: Record<string, any>, isHTML = false): string => {
        if (isHTML) {
            return intl.formatMessage({ id: text, defaultMessage: text }, data);
        }

        return intl.formatMessage({ id: text, defaultMessage: text }, data)?.toString() || '';
    };

    return { __ };
};

export default useTranslate;
