import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Navigate } from 'react-router-dom';

const RequireAuth = ({ children }: React.PropsWithChildren) => {
    const isAuthenticated = useSelector((state: RootState) => state.user.authenticated);

    if (!isAuthenticated) {
        return <Navigate to={'/login'} />;
    }

    return <>{children}</>;
};

export default RequireAuth;
