import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getEventTypesService, updateEventTypePricesService } from '../../services/event-types.service';
import { useErrorHandler } from '../../controllers/Error';
import { useNotifications } from '../../controllers/Notifications';
import useTranslate from '../useTranslate';

const useEventTypes = () => {
    const queryClient = useQueryClient();
    const { __ } = useTranslate();
    const { successMsg } = useNotifications();
    const errorHandler = useErrorHandler();

    const {
        isError: isGetEventTypesError,
        isPending: isPendingGetEventTypes,
        data: eventTypesData,
        error: getEventTypesError,
    } = useQuery({
        queryKey: ['event-types'],
        queryFn: () => getEventTypesService(),
        staleTime: 1000 * 60 * 5,
    });

    if (isGetEventTypesError) {
        errorHandler({ error: getEventTypesError, message: 'Nie udało się pobrać listy wydarzeń.' });
    }

    const {
        mutate: updatePrices,
        isPending: isPendingUpdatePrices,
        isError: isErrorUpdatePrices,
        error: updatePricesError,
        isSuccess: isSuccessUpdatePrices,
    } = useMutation({
        mutationKey: ['update-event-type-prices'],
        mutationFn: async (data: { prices: Array<{ price: number; id: string }> }) => {
            const response = await updateEventTypePricesService(data);

            return response;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['event-types'], data);
        },
    });

    if (isSuccessUpdatePrices) {
        successMsg(__('Cennik został zaktualizowany'));
    }

    if (isErrorUpdatePrices) {
        errorHandler({ error: updatePricesError, message: 'Nie udało się zaktualizować cennika.' });
    }

    return {
        isGetEventTypesError,
        isPendingGetEventTypes,
        getEventTypesError,
        eventTypesData,
        updatePrices,
        isPendingUpdatePrices,
        isErrorUpdatePrices,
    };
};

export default useEventTypes;
