import * as Sentry from '@sentry/react';
import { useAppDispatch } from '../../hooks';
import { logOutUser, setUser } from '../../slices/user.slice';
import { useNotifications } from '../Notifications';
import { Cookie } from '../../misc/helpers';
import axios, { AxiosError, CanceledError } from 'axios';
import ERROR_CODES from '../../config/errorCodes';
import useTranslate from '../../hooks/useTranslate';

interface Props {
    error: AxiosError<{ errorCode: number }> | CanceledError<{ code?: string }> | unknown;
    message?: string;
}

export const useErrorHandler = () => {
    const dispatch = useAppDispatch();
    const { errorMsg } = useNotifications();
    const { __ } = useTranslate();

    const isAuthStatus = (error: AxiosError) => {
        return error?.response?.status === 401;
    };

    const newError = ({ error, message }: Props) => {
        if (axios.isCancel(error)) {
            return;
        }

        if (isAuthStatus(error as AxiosError)) {
            Cookie.delete('kkt');
            Cookie.delete('kkrt');
            dispatch(setUser(null));
            dispatch(logOutUser());

            window.location.href = '/login';

            return;
        }

        Sentry.captureException(error);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (error?.response?.data?.errorCode && ERROR_CODES[error.response.data.errorCode]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            errorMsg(ERROR_CODES[error.response.data.errorCode]);
            return;
        }

        if (message) {
            errorMsg(
                __(message) +
                    '' +
                    __(
                        'Spróbuj ponownie za chwilę. Jeśli problem będzie się powtarzał skontaktuj się z administratorem aplikacji.',
                    ),
            );
            return;
        }

        errorMsg(
            __(
                'Wystąpił błąd, spróbuj ponownie za chwilę. Jeśli problem będzie się powtarzał skontaktuj się z administratorem aplikacji.',
            ),
        );
    };

    return newError;
};
