import MessageListItem from './MessageListItem.tsx';
import { NotificationsListBoxStyles, NotificationsListStyles } from '../../../styles/notifications.ts';
import { MessageEntity } from '../../../entities/MessageEntity.ts';
import { Alert } from '@mui/lab';

interface Props {
    messages: Array<MessageEntity>;
    updateMessages: () => void;
}

const MessageList = ({ messages, updateMessages }: Props) => {
    return (
        <NotificationsListStyles>
            <NotificationsListBoxStyles>
                {!messages.length ? (
                    <Alert severity="info" sx={{ mx: 2, my: 2 }}>
                        Brak nowych wiadomości.
                    </Alert>
                ) : null}
                {messages.map((message: MessageEntity) => {
                    return <MessageListItem updateMessages={updateMessages} message={message} key={message.id} />;
                })}
            </NotificationsListBoxStyles>
        </NotificationsListStyles>
    );
};

export default MessageList;
