import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, useMediaQuery } from '@mui/material';

import AppBarStyled from './AppBarStyled';
import HeaderContent from '../TopBar';
import { IconLayoutSidebarLeftCollapseFilled, IconLayoutSidebarLeftExpandFilled } from '@tabler/icons-react';
import { AvatarSecondaryStyles } from '../../../styles/avatar';

interface Props {
    open: boolean;
    handleDrawerToggle: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    // common header
    const mainHeader = (
        <Toolbar>
            <Box style={{ display: 'flex' }}>
                <AvatarSecondaryStyles
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    sx={{ ml: { xs: 0, lg: !open ? 0.5 : -2 } }}
                >
                    {open ? (
                        <IconLayoutSidebarLeftCollapseFilled stroke={1} size="20px" />
                    ) : (
                        <IconLayoutSidebarLeftExpandFilled stroke={1} size="20px" />
                    )}
                </AvatarSecondaryStyles>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'end' }}>
                <HeaderContent />
            </Box>
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
    };

    return (
        <>
            {!matchDownMD ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

export default Header;
