import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useAppDispatch } from './hooks';
import { loginUser, setUser } from './slices/user.slice';
import { getCurrentUserService } from './services/user.service';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { useErrorHandler } from './controllers/Error';
import { Cookie } from './misc/helpers.ts';
import { getStableByID } from './services/stable.service.ts';
import { setStable } from './slices/stable.slice.ts';
import LoadingPage from './pages/Loading/index.tsx';
import { getSubscriptionDataService } from './services/subscription.service.ts';
import { setSubscription } from './slices/subscription.slice.ts';

const App = ({ children }: React.PropsWithChildren) => {
    const [pending, setPending] = useState(false);

    const user = useSelector((state: RootState) => state.user.user);
    const stable = useSelector((state: RootState) => state.stable.stable);

    let abortUserController: AbortController | null = null;
    let abortStableController: AbortController | null = null;
    let abortSubscriptionController: AbortController | null = null;
    const dispatch = useAppDispatch();
    const errorHandler = useErrorHandler();

    const getUser = async () => {
        abortUserController = new AbortController();
        setPending(true);

        try {
            const { data } = await getCurrentUserService({ signal: abortUserController.signal });

            dispatch(loginUser());
            dispatch(setUser(data));

            Sentry.setUser({ ...user });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const getStable = async () => {
        if (!user) {
            return;
        }

        abortStableController = new AbortController();

        setPending(true);

        try {
            const { data } = await getStableByID(user.stable, { signal: abortStableController.signal });

            dispatch(setStable(data));
        } catch (error) {
            errorHandler({ error });
        }

        setPending(false);
    };

    const getSubscription = async () => {
        if (!user) {
            return;
        }

        abortSubscriptionController = new AbortController();

        setPending(true);

        try {
            const { data } = await getSubscriptionDataService({ signal: abortSubscriptionController.signal });

            dispatch(setSubscription(data));
        } catch (error) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((error as any)?.response?.status !== 404) {
                errorHandler({ error });
            }
        }

        setPending(false);
    };

    useEffect(() => {
        const token = Cookie.get('kkt');

        if (token && !user) {
            getUser();
        }

        return () => {
            abortUserController?.abort();
        };
    }, [user]);

    useEffect(() => {
        if (user && !stable) {
            getStable();
        }

        return () => {
            abortStableController?.abort();
        };
    }, [user, stable]);

    useEffect(() => {
        if (user) {
            getSubscription();
        }

        return () => {
            abortSubscriptionController?.abort();
        };
    }, [user]);

    return <>{pending || (Cookie.get('kkt') && !user) ? <LoadingPage /> : children}</>;
};

export default Sentry.withProfiler(App);
