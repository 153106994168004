import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import UserMenuAvatar from './UserMenuAvatar';
import Notifications from './Notifications';
import Helpdesk from './Helpdesk';

const TopBar = () => {
    const user = useSelector((state: RootState) => state.user.user);

    return (
        <>
            {/* <LocalizationSection /> */}

            <Helpdesk />

            <Notifications />

            <Box sx={{ flexGrow: 0, marginLeft: 1 }}>
                <UserMenuAvatar user={user} />
            </Box>
        </>
    );
};

export default TopBar;
