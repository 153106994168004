import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserEntity } from '../entities/UserEntity';

interface UserSlice {
    authenticated: boolean;
    user: UserEntity | null;
}

const initialState: UserSlice = {
    authenticated: false,
    user: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state) => {
            state.authenticated = true;
        },
        logOutUser: (state) => {
            state.authenticated = false;
        },
        setUser: (state, action: PayloadAction<UserEntity | null>) => {
            state.user = action.payload;
        },
    },
});

export const { loginUser, logOutUser, setUser } = userSlice.actions;

export const isAuthenticated = (state: RootState) => state.user.authenticated;

export default userSlice.reducer;
