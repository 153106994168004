import Layout from '../components/Layout';
import Loadable from '../components/Loadable';
import { lazy } from 'react';
import RequireAuth from '../components/RequireAuth';

const LazyClientsList = Loadable(lazy(() => import('../pages/Clients/List')));
const LazyEditClient = Loadable(lazy(() => import('../pages/Clients/Edit')));
const LazyClientProfile = Loadable(lazy(() => import('../pages/Clients/Profile')));

const Clients = {
    path: '/clients',
    element: (
        <RequireAuth>
            <Layout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/clients',
            element: <LazyClientsList />,
        },
        {
            path: '/clients/edit',
            element: <LazyEditClient />,
        },
        {
            path: '/clients/edit/:id',
            element: <LazyEditClient />,
        },
        {
            path: '/clients/profile/:id',
            element: <LazyClientProfile />,
        },
    ],
};

export default Clients;
