import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionEntity } from '../entities/SubscriptionEntity';
import { SUBSCRIPTION_FREE_PLAN, SUBSCRIPTION_NOT_ACTIVE_STATUSES } from '../config/consts';

interface SubscriptionSlice {
    data: SubscriptionEntity | null;
    isActive: boolean;
    isCreated: boolean;
    horseLimit: number;
    userLimit: number;
    subscriptionId?: string;
}

const initialState: SubscriptionSlice = {
    subscriptionId: undefined,
    data: null,
    isActive: false,
    isCreated: false,
    horseLimit: SUBSCRIPTION_FREE_PLAN.horseLimit,
    userLimit: SUBSCRIPTION_FREE_PLAN.userLimit,
};

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setSubscription: (state, action: PayloadAction<SubscriptionEntity | null>) => {
            state.data = action.payload;

            if (!action.payload) {
                state.isCreated = false;
                state.isActive = false;
                return;
            }

            state.subscriptionId = action.payload.subscriptionID;
            state.isCreated = true;
            state.isActive = !SUBSCRIPTION_NOT_ACTIVE_STATUSES.includes(action.payload?.status);

            if (state.isActive) {
                state.horseLimit =
                    action.payload.subscriptionItems.find((item) => item.id === 'horseLimit')?.quantity ||
                    SUBSCRIPTION_FREE_PLAN.horseLimit;
                state.userLimit =
                    action.payload.subscriptionItems.find((item) => item.id === 'userLimit')?.quantity ||
                    SUBSCRIPTION_FREE_PLAN.userLimit;
            } else {
                state.horseLimit = SUBSCRIPTION_FREE_PLAN.horseLimit;
                state.userLimit = SUBSCRIPTION_FREE_PLAN.userLimit;
            }
        },
    },
});

export const { setSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
