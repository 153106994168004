import { toast } from 'react-toastify';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ToastProps } from 'react-toastify/dist/types';

export const useNotifications = () => {
    const defaults: Partial<ToastProps> = {
        position: 'top-center',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
    };

    const successMsg = (msg: string) => {
        toast.success(msg, defaults);
    };

    const errorMsg = (msg: string) => {
        toast.error(msg, defaults);
    };

    return { successMsg, errorMsg };
};
