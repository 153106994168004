import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import LogoVertical from '../../components/Logo/LogoVertical';

const LoadingPage = () => {
    return (
        <Grid container alignItems={'center'} justifyContent={'center'} sx={{ height: '100vh' }}>
            <Grid item sx={{ textAlign: 'center' }}>
                <Box sx={{ mb: 4 }}>
                    <LogoVertical width={100} />
                </Box>
                <Box>
                    <CircularProgress />
                </Box>
                <Typography variant="caption">Pobieram dane...</Typography>
            </Grid>
        </Grid>
    );
};

export default LoadingPage;
